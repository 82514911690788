import {Injectable} from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import {IAnnouncement} from '../../shared/model/announcement.model';
import {AnnouncementRepository} from '../repository/announcement.repository';
import { getOpportunityAnnouncementRecordsAction } from '../store/opportunity-config/opportunity-config.actions';
import { getOpportunityAnnouncementRecordsSelector } from '../store/opportunity-config/opportunity-config.selectors';


@Injectable({providedIn: 'root'})
export class AnnouncementService {
  constructor(private announcementRepo: AnnouncementRepository, private store: Store) {}

  public getAnnouncement(id: string): Observable<IAnnouncement> {
    return this.announcementRepo.getAnnouncement(id);
  }

  public deleteAnnouncement(id: string): Observable<string> {
    return this.announcementRepo.deleteAnnouncement(id);
  }

  public addAnnouncement(announcement: Partial<IAnnouncement>): Observable<IAnnouncement> {
    return this.announcementRepo.addAnnouncement(announcement);
  }

  public updateAnnouncement(announcement: IAnnouncement): Observable<IAnnouncement> {
    return this.announcementRepo.updateAnnouncement(announcement);
  }

  /**
   * Retrieves announcements for a given opportunity.
   * This method doesn't use the state, so it should be used everywhere except the supervisor dashboard announcements tab.
   * For that component, use {@link getOpportunityAnnouncementsFromState}
   * @param opportunityId
   * @returns {Observable<IOpportunity[]>}
   */
  public getOpportunityAnnouncements(opportunityId: string): Observable<IAnnouncement[]> {
    return this.announcementRepo.getOpportunityAnnouncements(opportunityId);
  }

  /**
   * Retrieves announcements for a given opportunity.
   * This method uses the state, so it should be used only on the supervisor dashboard announcements tab.
   * For other locations, use {@link getOpportunityAnnouncements}
   * @param opportunityId
   * @returns {Observable<IOpportunity[]>}
   */
  public getOpportunityAnnouncementsFromState(opportunityId: string): Observable<IAnnouncement[]>{
    this.store.dispatch(getOpportunityAnnouncementRecordsAction({id: opportunityId}));
    return this.store.pipe(select(getOpportunityAnnouncementRecordsSelector));
  }

  public getAdminAnnouncements(): Observable<IAnnouncement[]> {
    return this.announcementRepo.getAdminAnnouncements();
  }
}

import { HttpClient } from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {environment} from '../../../environments/environment';
import {IAnnouncement} from '../../shared/model/announcement.model';


const BASE_URL: string = `${environment.apiUrl}/announcements`

@Injectable({providedIn: 'root'})
export class AnnouncementRepository {
  constructor(private http: HttpClient) {}

  public getAnnouncement(id: string): Observable<IAnnouncement> {
    return this.http.get<IAnnouncement>(`${BASE_URL}/${id}`);
  }

  public addAnnouncement(announcement: Partial<IAnnouncement>): Observable<IAnnouncement> {
    return this.http.post<IAnnouncement>(`${BASE_URL}`, announcement);
  }

  public deleteAnnouncement(id: string): Observable<string> {
    return this.http.delete<string>(`${BASE_URL}/${id}`);
  }

  public updateAnnouncement(announcement: IAnnouncement): Observable<IAnnouncement> {
    return this.http.put<IAnnouncement>(`${BASE_URL}/${announcement.id}`, announcement);
  }

  public getOpportunityAnnouncements(opportunityId: string) {
    return this.http.get<IAnnouncement[]>(`${BASE_URL}/opportunity/${opportunityId}`);
  }

  public getAdminAnnouncements() {
    return this.http.get<IAnnouncement[]>(`${BASE_URL}/admin`);
  }
}
